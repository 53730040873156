import { useState } from 'react';
import { NotePencil, Trash } from '@phosphor-icons/react';
import { IconButton } from '@la/ds-ui-components';
import {
  CartItemOptionsSubprogramsDetail,
  CartItemOptionsSubprogramsRegistration,
} from '@la/types';
import { getLAHostnameParts } from '@la/utilities';
import { useConsolidatedCheckout } from 'lib/context/ConsolidatedCheckoutContext/ConsolidatedCheckoutContext';
import { useDeletePlayerStaffRegistrationMutation } from 'redux/services/checkoutApi';
import RemoveFromCartModal from '../RemoveFromCartModal/RemoveFromCartModal';
import styles from './OrderSummaryCard.module.scss';

/* ProgramRegistrationsTable */
export default function ProgramRegistrationsTable({
  cartItemUuid,
  subprograms,
  subtype,
}: Readonly<{
  cartItemUuid: string;
  subprograms: CartItemOptionsSubprogramsDetail[];
  subtype: string;
}>) {
  switch (subtype) {
    case 'team':
      return (
        <TeamProgramRegistrationsTable
          cartItemUuid={cartItemUuid}
          subprograms={subprograms}
        />
      );
    case 'player':
    case 'staff':
      return (
        <PlayerStaffRegistrationsTable
          cartItemUuid={cartItemUuid}
          subprograms={subprograms}
          subtype={subtype}
        />
      );
    default:
      return null;
  }
}
/* */

/* TeamProgramRegistrationsTable */
function TeamProgramRegistrationsTable({
  cartItemUuid,
  subprograms,
}: Readonly<{
  cartItemUuid: string;
  subprograms: CartItemOptionsSubprogramsDetail[];
}>) {
  const headers: string[] = ['Program', 'Team', 'Total price'];
  return (
    <table
      className={styles.programRegistrationsTable}
      aria-label="team program registrations"
    >
      <ProgramRegistrationsTableHeader
        cartItemUuid={cartItemUuid}
        headers={headers}
      />
      <TeamProgramRegistrationsTableBody subprograms={subprograms} />
    </table>
  );
}
/* */

/* TeamProgramRegistrationsTableBody */
function TeamProgramRegistrationsTableBody({
  subprograms,
}: Readonly<{ subprograms: CartItemOptionsSubprogramsDetail[] }>) {
  return (
    <tbody>
      {subprograms.map(({ subprogramName, registrations }, idx) => (
        <TeamProgramRegistrationsTableRow
          key={`t-${subprogramName}-${idx}`}
          subprogramName={subprogramName}
          registrations={registrations}
        />
      ))}
    </tbody>
  );
}

/* */

/* TeamProgramRegistrationsTableRow */
function TeamProgramRegistrationsTableRow({
  subprogramName,
  registrations,
}: Readonly<{
  subprogramName: string;
  registrations: CartItemOptionsSubprogramsRegistration[];
}>) {
  return (
    <>
      {registrations.map(({ basePrice, registrationId, teamName }) => (
        <tr key={registrationId}>
          <td>{subprogramName}</td>
          <td>{teamName}</td>
          <td>${basePrice}</td>
        </tr>
      ))}
    </>
  );
}
/* */

/* PlayerStaffRegistrationsTable */
function PlayerStaffRegistrationsTable({
  cartItemUuid,
  subprograms,
  subtype,
}: Readonly<{
  cartItemUuid: string;
  subprograms: CartItemOptionsSubprogramsDetail[];
  subtype: string;
}>) {
  const headers = [];
  if (subtype === 'player') {
    headers.push('Player');
  } else if (subtype === 'staff') {
    headers.push('Staff', 'Role');
  }
  headers.push('Status', 'Program', 'Team', 'Total price', '', '');

  return (
    <table
      className={styles.programRegistrationsTable}
      aria-label={`${subtype} program registrations`}
    >
      <ProgramRegistrationsTableHeader
        cartItemUuid={cartItemUuid}
        headers={headers}
      />
      <PlayerStaffRegistrationsTableBody
        cartItemUuid={cartItemUuid}
        subprograms={subprograms}
        subtype={subtype}
      />
    </table>
  );
}
/* */

/* PlayerStaffRegistrationsTableBody */
function PlayerStaffRegistrationsTableBody({
  cartItemUuid,
  subprograms,
  subtype,
}: Readonly<{
  cartItemUuid: string;
  subprograms: CartItemOptionsSubprogramsDetail[];
  subtype: string;
}>) {
  return (
    <tbody>
      {subprograms.map(
        ({ subprogramId, subprogramName, registrations }, idx) => (
          <PlayerStaffRegistrationsTableRow
            cartItemUuid={cartItemUuid}
            key={`ps-${subprogramName}-${idx}`}
            registrations={registrations}
            subprogramId={subprogramId}
            subprogramName={subprogramName}
            subtype={subtype}
          />
        )
      )}
    </tbody>
  );
}

/* */

/* PlayerStaffRegistrationsTableRow */
function PlayerStaffRegistrationsTableRow({
  cartItemUuid,
  registrations,
  subprogramId,
  subprogramName,
  subtype,
}: Readonly<{
  cartItemUuid: string;
  registrations: CartItemOptionsSubprogramsRegistration[];
  subprogramId: number;
  subprogramName: string;
  subtype: string;
}>) {
  return (
    <>
      {registrations.map(
        (registration: CartItemOptionsSubprogramsRegistration) => (
          <tr key={registration.registrationId}>
            <td>{registration.userName}</td>
            {subtype === 'staff' ? <td>{registration.role}</td> : null}
            <td>{registration.teamName ? 'Team' : 'Free Agent'}</td>
            <td>{subprogramName}</td>
            <td>{registration.teamName}</td>
            <td>${registration.basePrice}</td>
            <RegistrationActions
              cartItemUuid={cartItemUuid}
              registration={registration}
              subprogramId={subprogramId}
            />
          </tr>
        )
      )}
    </>
  );
}
/* */

/*ProgramRegistrationsTableHeader */
function ProgramRegistrationsTableHeader({
  cartItemUuid,
  headers,
}: Readonly<{ cartItemUuid: string; headers: string[] }>) {
  return (
    <thead>
      <tr>
        {headers.map((header, idx) => {
          const headerCellKey =
            header !== ''
              ? `${header}-${cartItemUuid}`
              : `header-${idx}-${cartItemUuid}`;
          return <th key={headerCellKey}>{header}</th>;
        })}
      </tr>
    </thead>
  );
}
/* */

/* RegistrationActions */
function RegistrationActions({
  cartItemUuid,
  registration,
  subprogramId,
}: Readonly<{
  cartItemUuid: string;
  registration: CartItemOptionsSubprogramsRegistration;
  subprogramId: number;
}>) {
  const { subdomain } = getLAHostnameParts();
  const { handleRegistrationRemoval } = useConsolidatedCheckout();
  const [deletePlayerStaffRegistration] =
    useDeletePlayerStaffRegistrationMutation();
  const [showRemoveItemModal, setShowRemoveItemModal] = useState(false);

  function onEditButtonClick() {
    window.location.href = `/app/registration/review?registrationId=${registration.registrationId}`;
  }

  function handlePlayerStaffRegistrationDeletion() {
    deletePlayerStaffRegistration({
      cartItemId: cartItemUuid,
      operation: 'delete',
      registration: registration,
      subdomain: subdomain,
      subprogramId: subprogramId,
    })
      .unwrap()
      .then(() => {
        handleRegistrationRemoval(
          cartItemUuid,
          registration.registrationId,
          subprogramId
        );
      });
  }

  return (
    <>
      <td>
        <IconButton
          ariaLabel="Edit"
          icon={<NotePencil weight="bold" />}
          onClick={onEditButtonClick}
          variant="text"
        />
      </td>
      <td>
        <IconButton
          ariaLabel="Delete"
          icon={<Trash weight="bold" />}
          onClick={() => setShowRemoveItemModal(true)}
          variant="text"
        />
      </td>
      {showRemoveItemModal ? (
        <RemoveFromCartModal
          open={showRemoveItemModal}
          closeModal={() => setShowRemoveItemModal(false)}
          removeCartItem={handlePlayerStaffRegistrationDeletion}
        />
      ) : null}
    </>
  );
}
/* */
