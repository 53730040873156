import {
  createContext,
  useContext,
  PropsWithChildren,
  useEffect,
  useCallback,
  useReducer,
  useMemo,
  useRef,
} from 'react';
import { PaymentMethodInfo, PaymentTerm, WaiverData } from '@la/types';
import { getLAHostnameParts } from '@la/utilities';
import { ToastVariant } from 'components/Toast/Toast';
import {
  useDeleteCartItemMutation,
  useGetCheckoutSummaryBFFQuery,
  useUpdateCheckoutSummaryBFFMutation,
} from 'redux/services/checkoutApi';
import { isSingleUseCard } from 'domains/Checkout/PaymentMethodCard/PaymentMethodCard';
import {
  CartDataFromUpdate,
  CheckoutSummaryData,
  CombinedCartItemData,
  DiscountCode,
  PaymentMethod,
  PaymentPlanSummary,
  SingleUseCard,
} from 'domains/ConsolidatedCheckout/Checkout.types';
import { MessagingContext } from '../MessagingContext';

export type DiscountCodeToastType = 'add' | 'duplicate' | 'remove';
type CheckoutFieldErrorStates = {
  hasPaymentMethodError?: boolean;
  hasPaymentPolicyError?: boolean;
};

export type ConsolidatedCheckoutData = {
  cartItems: CombinedCartItemData[];
  cartUuid?: string;
  checkoutErrorsAreVisible: boolean;
  checkoutFieldErrorStates: CheckoutFieldErrorStates;
  currency?: string;
  discountCodeCodes: string[];
  discountCodeErrors: string[];
  discountCodes: DiscountCode[];
  dueToday: string;
  hasAgreedToAutopay: boolean;
  hasAgreedToPaymentPolicy: boolean;
  hasAutopayPaymentOptionSelected: boolean;
  isLoading: string[];
  paymentWaiver?: WaiverData;
  selectedPaymentMethod: PaymentMethod;
  selectedPaymentPlans?: PaymentPlanSummary[];
  storedPaymentMethods: PaymentMethodInfo;
  submissionErrors: string[];
  submissionErrorsAreVisible: boolean;
  subtotal?: string;
  temporaryPaymentMethod?: SingleUseCard;
  totalAmount?: string;
  transactionFees: string;
  updateCartItems: (updatedCartItems: CombinedCartItemData[]) => void;
  updateCheckoutErrorsAreVisible: (checkoutErrorsAreVisible: boolean) => void;
  updateDataAfterCartItemDeletion: (
    updatedDataAfterCartItemDeletion: CartDataFromUpdate
  ) => void;
  updateDataAfterDiscountCodesUpdate: (
    updatedDataAfterDiscountCodesUpdate: CartDataFromUpdate
  ) => void;
  updateDataAfterPaymentMethodSelection: (
    updatedDataAfterPaymentMethodSelectionUpdate: CartDataFromUpdate
  ) => void;
  updateDataAfterPaymentPlanSelection: (
    updatedDataAfterPaymentPlanSelectionUpdate: CartDataFromUpdate
  ) => void;
  updateDiscountCodeErrors: (errorMessages: string[]) => void;
  updateHasAgreedToAutopay: (hasAgreedToAutopay: boolean) => void;
  updateHasAgreedToPaymentPolicy: (hasAgreedToPaymentPolicy: boolean) => void;
  updatePaymentMethodErrors: (displayError: boolean) => void;
  updatePaymentWaiver: (paymentWaiver?: WaiverData) => void;
  updatePaymentWaiverErrors: (displayError: boolean) => void;
  updateSelectedPaymentMethod: (paymentMethod: PaymentMethod) => void;
  updateStoredPaymentMethods: (storedPaymentMethods: PaymentMethodInfo) => void;
  updateSubmissionErrors: (errorMessages: string[]) => void;
  updateSubmissionErrorsAreVisible: (displayError: boolean) => void;
  updateTemporaryPaymentMethod: (singleUseCard: SingleUseCard) => void;
};

const { subdomain } = getLAHostnameParts();

export const consolidatedCheckoutDefaults: ConsolidatedCheckoutData = {
  cartItems: [],
  cartUuid: '',
  checkoutErrorsAreVisible: false,
  checkoutFieldErrorStates: {
    hasPaymentMethodError: false,
    hasPaymentPolicyError: false,
  },
  currency: 'USD',
  discountCodes: [],
  discountCodeErrors: [],
  discountCodeCodes: [],
  dueToday: '0.00',
  hasAgreedToAutopay: false,
  hasAgreedToPaymentPolicy: false,
  hasAutopayPaymentOptionSelected: false,
  isLoading: ['page'],
  paymentWaiver: undefined,
  selectedPaymentMethod: null,
  selectedPaymentPlans: [],
  storedPaymentMethods: {
    paymentOptionsCount: 0,
    storedBankAccounts: [],
    storedCreditCards: [],
  },
  submissionErrors: [],
  submissionErrorsAreVisible: false,
  subtotal: '0.00',
  temporaryPaymentMethod: undefined,
  totalAmount: '0.00',
  transactionFees: '0.00',
  updateCartItems: () => {},
  updateCheckoutErrorsAreVisible: () => {},
  updateDataAfterCartItemDeletion: () => {},
  updateDataAfterDiscountCodesUpdate: () => {},
  updateDataAfterPaymentMethodSelection: () => {},
  updateDataAfterPaymentPlanSelection: () => {},
  updateDiscountCodeErrors: () => {},
  updateHasAgreedToAutopay: () => {},
  updateHasAgreedToPaymentPolicy: () => {},
  updatePaymentMethodErrors: () => {},
  updatePaymentWaiver: () => {},
  updatePaymentWaiverErrors: () => {},
  updateSelectedPaymentMethod: () => {},
  updateStoredPaymentMethods: () => {},
  updateSubmissionErrors: () => {},
  updateSubmissionErrorsAreVisible: () => {},
  updateTemporaryPaymentMethod: () => {},
};

function checkoutCartReducer(
  state: ConsolidatedCheckoutData,
  action: { type: string; payload: any }
): ConsolidatedCheckoutData {
  const { type, payload } = action;
  switch (type) {
    // Set Initial State
    case 'SET_INITIAL_LOAD_STATE': {
      return { ...state, ...payload };
    }

    // Payment Method
    case 'UPDATE_STORED_PAYMENT_METHODS':
      return {
        ...state,
        storedPaymentMethods: payload,
      };
    case 'UPDATE_SELECTED_PAYMENT_METHOD':
      return { ...state, selectedPaymentMethod: payload };
    case 'UPDATE_TEMPORARY_PAYMENT_METHOD':
      return { ...state, temporaryPaymentMethod: payload };

    // Payment Plan Selection
    case 'UPDATE_SELECTED_PAYMENT_PLANS':
      return { ...state, selectedPaymentPlans: payload };

    // Discount Codes
    case 'UPDATE_DISCOUNT_CODES':
      return {
        ...state,
        discountCodes: payload,
      };
    case 'UPDATE_DISCOUNT_CODE_CODES':
      return {
        ...state,
        discountCodeCodes: payload,
      };

    // Error Messages
    case 'UPDATE_DISCOUNT_CODE_ERRORS':
      return {
        ...state,
        discountCodeErrors: payload,
      };
    case 'UPDATE_SUBMISSION_ERRORS':
      return {
        ...state,
        submissionErrors: payload,
      };
    case 'UPDATE_CHECKOUT_ERRORS_ARE_VISIBLE':
      return {
        ...state,
        checkoutErrorsAreVisible: payload,
      };
    case 'UPDATE_SUBMISSION_ERRORS_ARE_VISIBLE':
      return {
        ...state,
        submissionErrorsAreVisible: payload,
      };
    case 'UPDATE_HAS_AGREED_TO_AUTOPAY':
      return { ...state, hasAgreedToAutopay: payload };
    case 'UPDATE_HAS_PAYMENT_PLAN_WITH_AUTOPAY':
      return { ...state, hasAutopayPaymentOptionSelected: payload };
    case 'UPDATE_HAS_AGREED_TO_PAYMENT_POLICY':
      return { ...state, hasAgreedToPaymentPolicy: payload };
    case 'SET_CART_UUID':
      return { ...state, cartUuid: payload };
    case 'SET_CURRENCY':
      return { ...state, currency: payload };
    case 'UPDATE_CART_ITEMS':
      return { ...state, cartItems: payload };
    case 'UPDATE_DUE_TODAY':
      return { ...state, dueToday: payload };
    case 'UPDATE_SUBTOTAL':
      return { ...state, subtotal: payload };
    case 'UPDATE_TOTAL_AMOUNT':
      return { ...state, totalAmount: payload };
    case 'UPDATE_TRANSACTION_FEES':
      return { ...state, transactionFees: payload };
    case 'UPDATE_PAYMENT_WAIVER':
      return { ...state, paymentWaiver: payload };
    case 'UPDATE_HAS_PAYMENT_METHOD_ERRORS':
      return {
        ...state,
        checkoutFieldErrorStates: {
          ...state.checkoutFieldErrorStates,
          hasPaymentMethodError: payload,
        },
      };
    case 'UPDATE_HAS_PAYMENT_POLICY_ERRORS':
      return {
        ...state,
        checkoutFieldErrorStates: {
          ...state.checkoutFieldErrorStates,
          hasPaymentPolicyError: payload,
        },
      };
    default:
      return state;
  }
}

const ConsolidatedCheckoutContext = createContext<ConsolidatedCheckoutData>(
  consolidatedCheckoutDefaults
);

const ConsolidatedCheckoutProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(
    checkoutCartReducer,
    consolidatedCheckoutDefaults
  );

  const hasSummaryDataRequestSucceeded = useRef(false);
  const { data: checkoutSummary } = useGetCheckoutSummaryBFFQuery(
    {
      subdomain: subdomain,
    },
    {
      skip: hasSummaryDataRequestSucceeded.current,
    }
  );

  const setCheckoutSummary = useCallback(
    (checkoutSummaryData: CheckoutSummaryData) => {
      dispatch({
        type: 'SET_INITIAL_LOAD_STATE',
        payload: { ...checkoutSummaryData, isLoading: [] },
      });
    },
    []
  );

  const updateCartItems = useCallback(
    (updatedCartItems: CombinedCartItemData[]) => {
      dispatch({ type: 'UPDATE_CART_ITEMS', payload: updatedCartItems });
    },
    []
  );

  const updateStoredPaymentMethods = useCallback(
    (storedPaymentMethods: PaymentMethodInfo) => {
      dispatch({
        type: 'UPDATE_STORED_PAYMENT_METHODS',
        payload: storedPaymentMethods,
      });
    },
    []
  );

  const updateTemporaryPaymentMethod = useCallback(
    (temporaryPaymentMethod: SingleUseCard) => {
      dispatch({
        type: 'UPDATE_TEMPORARY_PAYMENT_METHOD',
        payload: temporaryPaymentMethod,
      });
    },
    []
  );

  /* Data Updates (Following Submission) */
  const updateDataAfterCartItemDeletion = useCallback(
    async (updatedData: any) => {
      const { cartItems, dueToday, totalAmount, transactionFees } = updatedData;
      const autopayRequired = cartItemsIncludeAutopayPaymentPlan(cartItems);

      dispatch({
        type: 'UPDATE_CART_ITEMS',
        payload: cartItems,
      });
      dispatch({
        type: 'UPDATE_DUE_TODAY',
        payload: dueToday,
      });
      dispatch({
        type: 'UPDATE_TOTAL_AMOUNT',
        payload: totalAmount,
      });
      dispatch({
        type: 'UPDATE_TRANSACTION_FEES',
        payload: transactionFees,
      });
      dispatch({
        type: 'UPDATE_HAS_PAYMENT_PLAN_WITH_AUTOPAY',
        payload: autopayRequired,
      });
    },
    []
  );

  const updateDataAfterDiscountCodesUpdate = useCallback(
    async (updatedData: CartDataFromUpdate) => {
      const {
        cartItems,
        discountCodes,
        dueToday,
        totalAmount,
        transactionFees,
      } = updatedData;
      const discountCodeCodes: string[] =
        getUniqueDiscountCodeCodes(discountCodes);

      dispatch({
        type: 'UPDATE_CART_ITEMS',
        payload: cartItems,
      });
      dispatch({
        type: 'UPDATE_DUE_TODAY',
        payload: dueToday,
      });
      dispatch({
        type: 'UPDATE_DISCOUNT_CODES',
        payload: discountCodes,
      });
      dispatch({
        type: 'UPDATE_DISCOUNT_CODE_CODES',
        payload: discountCodeCodes,
      });
      dispatch({
        type: 'UPDATE_DISCOUNT_CODE_ERRORS',
        payload: [],
      });
      dispatch({
        type: 'UPDATE_TOTAL_AMOUNT',
        payload: totalAmount,
      });
      dispatch({
        type: 'UPDATE_TRANSACTION_FEES',
        payload: transactionFees,
      });
    },
    []
  );

  const updateDataAfterPaymentMethodSelection = useCallback(
    (updatedData: CartDataFromUpdate) => {
      const { cartItems, dueToday, totalAmount, transactionFees } = updatedData;
      dispatch({
        type: 'UPDATE_CART_ITEMS',
        payload: cartItems,
      });
      dispatch({
        type: 'UPDATE_DUE_TODAY',
        payload: dueToday,
      });
      dispatch({
        type: 'UPDATE_TOTAL_AMOUNT',
        payload: totalAmount,
      });
      dispatch({
        type: 'UPDATE_TRANSACTION_FEES',
        payload: transactionFees,
      });
    },
    []
  );

  const updateDataAfterPaymentPlanSelection = useCallback(
    (updatedData: CartDataFromUpdate) => {
      const { cartItems, dueToday, totalAmount, transactionFees } = updatedData;
      const autopayRequired = cartItemsIncludeAutopayPaymentPlan(cartItems);

      dispatch({
        type: 'UPDATE_CART_ITEMS',
        payload: cartItems,
      });
      dispatch({
        type: 'UPDATE_DUE_TODAY',
        payload: dueToday,
      });
      dispatch({
        type: 'UPDATE_TOTAL_AMOUNT',
        payload: totalAmount,
      });
      dispatch({
        type: 'UPDATE_TRANSACTION_FEES',
        payload: transactionFees,
      });
      dispatch({
        type: 'UPDATE_HAS_PAYMENT_PLAN_WITH_AUTOPAY',
        payload: autopayRequired,
      });
    },
    []
  );
  /* */

  /* Update Errors */
  const updateDiscountCodeErrors = useCallback((errorMessages: string[]) => {
    dispatch({
      type: 'UPDATE_DISCOUNT_CODE_ERRORS',
      payload: errorMessages,
    });
  }, []);

  const updatePaymentMethodErrors = useCallback((displayError: boolean) => {
    dispatch({
      type: 'UPDATE_HAS_PAYMENT_METHOD_ERRORS',
      payload: displayError,
    });
  }, []);

  const updatePaymentWaiverErrors = useCallback((displayError: boolean) => {
    dispatch({
      type: 'UPDATE_HAS_PAYMENT_POLICY_ERRORS',
      payload: displayError,
    });
  }, []);

  const updateSubmissionErrors = useCallback((submissionErrors: string[]) => {
    dispatch({ type: 'UPDATE_SUBMISSION_ERRORS', payload: submissionErrors });
  }, []);
  /* */

  /* Update Error Visibility */
  const updateCheckoutErrorsAreVisible = useCallback(
    (checkoutErrorsAreVisible: boolean) => {
      dispatch({
        type: 'UPDATE_CHECKOUT_ERRORS_ARE_VISIBLE',
        payload: checkoutErrorsAreVisible,
      });
    },
    []
  );

  const updateSubmissionErrorsAreVisible = useCallback(
    (areSubmissionErrorsVisible: boolean) => {
      if (!areSubmissionErrorsVisible) {
        updateSubmissionErrors([]);
      }
      dispatch({
        type: 'UPDATE_SUBMISSION_ERRORS_ARE_VISIBLE',
        payload: areSubmissionErrorsVisible,
      });
    },
    [updateSubmissionErrors]
  );
  /* */

  const updateSelectedPaymentMethod = useCallback(
    (selectedPaymentMethod: PaymentMethod) => {
      dispatch({
        type: 'UPDATE_SELECTED_PAYMENT_METHOD',
        payload: selectedPaymentMethod,
      });
      updatePaymentMethodErrors(
        !selectedPaymentMethod ||
          (!selectedPaymentMethod.paymentMethodId &&
            state.hasAutopayPaymentOptionSelected)
      );
    },
    [state.hasAutopayPaymentOptionSelected, updatePaymentMethodErrors]
  );

  const setSelectedPaymentMethodToDefault = useCallback(
    (storedPaymentMethods: PaymentMethodInfo) => {
      const defaultPaymentMethod =
        getDefaultPaymentMethod(storedPaymentMethods);
      if (defaultPaymentMethod) {
        updateSelectedPaymentMethod(defaultPaymentMethod);
      }
    },
    [updateSelectedPaymentMethod]
  );

  const updatePaymentWaiver = useCallback((paymentWaiver?: WaiverData) => {
    dispatch({ type: 'UPDATE_PAYMENT_WAIVER', payload: paymentWaiver });
  }, []);

  /* Agreements */
  const updateHasAgreedToAutopay = useCallback(
    (hasAgreedToAutopay: boolean) => {
      dispatch({
        type: 'UPDATE_HAS_AGREED_TO_AUTOPAY',
        payload: hasAgreedToAutopay,
      });
      if (state.hasAutopayPaymentOptionSelected) {
        updatePaymentMethodErrors(!hasAgreedToAutopay);
      } else {
        updatePaymentMethodErrors(false);
      }
    },
    [state.hasAutopayPaymentOptionSelected, updatePaymentMethodErrors]
  );

  const updateHasAgreedToPaymentPolicy = useCallback(
    (hasAgreedToPaymentPolicy: boolean) => {
      dispatch({
        type: 'UPDATE_HAS_AGREED_TO_PAYMENT_POLICY',
        payload: hasAgreedToPaymentPolicy,
      });
      if (state.paymentWaiver) {
        updatePaymentWaiverErrors(!hasAgreedToPaymentPolicy);
      } else {
        updatePaymentWaiverErrors(false);
      }
    },
    [state.paymentWaiver, updatePaymentWaiverErrors]
  );
  /* */

  useEffect(() => {
    if (checkoutSummary && !hasSummaryDataRequestSucceeded.current) {
      hasSummaryDataRequestSucceeded.current = true;
      setCheckoutSummary(checkoutSummary);
      setSelectedPaymentMethodToDefault(checkoutSummary.storedPaymentMethods);
    }
  }, [checkoutSummary, setCheckoutSummary, setSelectedPaymentMethodToDefault]);

  const memoizedState = useMemo(
    () => ({
      ...state,
      updateCartItems,
      updateCheckoutErrorsAreVisible,
      updateDataAfterCartItemDeletion,
      updateDataAfterDiscountCodesUpdate,
      updateDataAfterPaymentMethodSelection,
      updateDataAfterPaymentPlanSelection,
      updateDiscountCodeErrors,
      updateHasAgreedToAutopay,
      updateHasAgreedToPaymentPolicy,
      updatePaymentMethodErrors,
      updatePaymentWaiver,
      updatePaymentWaiverErrors,
      updateSelectedPaymentMethod,
      updateStoredPaymentMethods,
      updateSubmissionErrors,
      updateSubmissionErrorsAreVisible,
      updateTemporaryPaymentMethod,
    }),
    [
      state,
      updateCartItems,
      updateCheckoutErrorsAreVisible,
      updateDataAfterCartItemDeletion,
      updateDataAfterDiscountCodesUpdate,
      updateDataAfterPaymentMethodSelection,
      updateDataAfterPaymentPlanSelection,
      updateDiscountCodeErrors,
      updateHasAgreedToAutopay,
      updateHasAgreedToPaymentPolicy,
      updatePaymentMethodErrors,
      updatePaymentWaiver,
      updatePaymentWaiverErrors,
      updateSelectedPaymentMethod,
      updateStoredPaymentMethods,
      updateSubmissionErrors,
      updateSubmissionErrorsAreVisible,
      updateTemporaryPaymentMethod,
    ]
  );

  return (
    <ConsolidatedCheckoutContext.Provider value={memoizedState}>
      {children}
    </ConsolidatedCheckoutContext.Provider>
  );
};

const useConsolidatedCheckout = () => {
  const {
    cartItems,
    cartUuid,
    checkoutErrorsAreVisible,
    checkoutFieldErrorStates,
    currency,
    discountCodeCodes,
    discountCodeErrors,
    discountCodes,
    dueToday,
    hasAgreedToAutopay,
    hasAgreedToPaymentPolicy,
    hasAutopayPaymentOptionSelected,
    isLoading,
    paymentWaiver,
    selectedPaymentMethod,
    submissionErrors,
    submissionErrorsAreVisible,
    subtotal,
    totalAmount,
    transactionFees,
    storedPaymentMethods,
    temporaryPaymentMethod,
    updateCartItems,
    updateCheckoutErrorsAreVisible,
    updateDataAfterCartItemDeletion,
    updateDataAfterDiscountCodesUpdate,
    updateDataAfterPaymentMethodSelection,
    updateDataAfterPaymentPlanSelection,
    updateDiscountCodeErrors,
    updateHasAgreedToAutopay,
    updateHasAgreedToPaymentPolicy,
    updatePaymentMethodErrors,
    updatePaymentWaiver,
    updatePaymentWaiverErrors,
    updateSelectedPaymentMethod,
    updateSubmissionErrors,
    updateSubmissionErrorsAreVisible,
    updateStoredPaymentMethods,
    updateTemporaryPaymentMethod,
  } = useContext<ConsolidatedCheckoutData>(ConsolidatedCheckoutContext);
  const [updateCheckoutSummary] = useUpdateCheckoutSummaryBFFMutation();
  const [deleteCartItem] = useDeleteCartItemMutation();
  const { addMessage } = useContext(MessagingContext);

  async function handleCartItemDeletion(cartItemUuid: string) {
    // First connect to the API to delete the cart item
    await deleteCartItem({
      siteDomain: subdomain,
      cartItemId: cartItemUuid,
    })
      .unwrap()
      .then(() => {
        getCheckoutSummaryFollowingDeletion(cartItemUuid);
      })
      .catch((error) => {
        console.log('error' + JSON.stringify(error));
      });
  }

  function getCheckoutSummaryFollowingDeletion(deletedCartItemUuid: string) {
    // Delete the cart item locally before passing cartItems to the BFF
    const updatedCartItems = cartItems.filter(
      (cartItem) => cartItem.cartItemUuid !== deletedCartItemUuid
    );
    updateCheckoutSummary({
      combinedCartItems: updatedCartItems,
      discountCodes: discountCodeCodes,
      paymentMethod: selectedPaymentMethod?.paymentType || 'CARD',
      subdomain,
    })
      .unwrap()
      .then((checkoutSummaryData) => {
        updateDataAfterCartItemDeletion(checkoutSummaryData);
      })
      .catch((error) => {
        console.log('error' + JSON.stringify(error));
      });
  }

  async function handleDiscountCodeUpdateSubmission(
    discountCodeToUpdate: string,
    addOrRemove: string
  ) {
    // Early return if no cartItems in state
    if (!cartItems || cartItems.length === 0) {
      return;
    }
    if (
      addOrRemove === 'add' &&
      discountCodeCodes.includes(discountCodeToUpdate)
    ) {
      showDiscountCodeToast(
        discountCodeToUpdate,
        'duplicate' as DiscountCodeToastType
      );
    } else {
      await handleDiscountCodesUpdate(discountCodeToUpdate, addOrRemove);
    }
  }

  async function handleDiscountCodesUpdate(
    discountCodeToUpdate: string,
    addOrRemove: string
  ) {
    const paymentMethod: string = selectedPaymentMethod?.paymentType || 'CARD';
    const discountCodesToApply: string[] = discountCodes
      ? getUpdatedDiscountCodeCodes(
          discountCodes,
          discountCodeToUpdate,
          addOrRemove
        )
      : [];

    updateCheckoutSummary({
      combinedCartItems: cartItems,
      discountCodes: discountCodesToApply,
      paymentMethod,
      subdomain,
    })
      .unwrap()
      .then((response) => {
        if (
          response.messages?.some(
            (message: { level: 'INFO' | 'WARN'; message: string }) =>
              message.level === 'WARN' &&
              message.message.includes('Could not apply discount code')
          )
        ) {
          const errorMessageToDisplay = extractFormattedMessageFromError(
            response.messages
          );
          throw Error(errorMessageToDisplay, {
            cause: 'Warning from code validation response.',
          });
        }

        showDiscountCodeToast(
          discountCodeToUpdate,
          addOrRemove as DiscountCodeToastType
        );
        updateDataAfterDiscountCodesUpdate(response);
      })
      .catch((error) => {
        if (error.cause === 'Warning from code validation response.') {
          updateDiscountCodeErrors([error.message]);
        } else {
          console.log('error' + JSON.stringify(error));
        }
      });
  }

  function showDiscountCodeToast(
    discountCodeName: string,
    updateType: DiscountCodeToastType
  ) {
    let message: string;
    let variant: ToastVariant;

    switch (updateType) {
      case 'add':
        message = 'has been successfully applied.';
        variant = 'success';
        break;
      case 'duplicate':
        message = 'has already been applied.';
        variant = 'info';
        break;
      case 'remove':
        message = 'has been successfully removed.';
        variant = 'success';
        break;
      default:
        message = 'was not applied';
        variant = 'info';
        break;
    }

    addMessage('toast', {
      description: `"${discountCodeName}" ${message}`,
      variant: variant,
    });
  }

  function handlePayLaterSelection(paymentPlanId: string, payLater: boolean) {
    const updatedCartItems = updatePayLaterInCartItemPaymentPlans(
      cartItems,
      paymentPlanId,
      payLater
    );
    updateCartItems(updatedCartItems);
  }

  async function handlePaymentMethodSelection(newPaymentMethod: PaymentMethod) {
    if (newPaymentMethod === null) {
      updatePaymentMethodErrors(false);
      return;
    }

    updateSelectedPaymentMethod(newPaymentMethod);

    const paymentMethod: string = newPaymentMethod.paymentType;
    updateCheckoutSummary({
      combinedCartItems: cartItems,
      discountCodes: discountCodeCodes,
      paymentMethod,
      subdomain,
    })
      .unwrap()
      .then((checkoutSummaryData) => {
        updateDataAfterPaymentMethodSelection(checkoutSummaryData);
      });
  }

  async function handlePaymentPlanSelection(
    selectedCartItemUuid: string,
    updatedPaymentPlanSelection: PaymentPlanSummary
  ) {
    // Early return if no cartItems in state
    if (!cartItems || cartItems.length === 0) {
      return;
    }

    const updatedCartItems = updatePaymentPlansInCartItems(
      cartItems,
      selectedCartItemUuid,
      updatedPaymentPlanSelection
    );

    updateCartItems(updatedCartItems);

    const paymentMethod: string = selectedPaymentMethod?.paymentType || 'CARD';
    updateCheckoutSummary({
      combinedCartItems: updatedCartItems,
      discountCodes: discountCodeCodes,
      paymentMethod,
      subdomain,
    })
      .unwrap()
      .then((checkoutSummaryData) => {
        updateDataAfterPaymentPlanSelection(checkoutSummaryData);
      });
  }

  async function handleRegistrationRemoval(
    cartItemUuid: string,
    deletedRegistrationUuid: string,
    subprogramId: number
  ) {
    const updatedCartItems = removeRegistrationFromCartItem(
      cartItems,
      cartItemUuid,
      subprogramId,
      deletedRegistrationUuid
    );
    updateCheckoutSummary({
      combinedCartItems: updatedCartItems,
      discountCodes: discountCodeCodes,
      paymentMethod: selectedPaymentMethod?.paymentType ?? 'CARD',
      subdomain,
    })
      .unwrap()
      .then((checkoutSummaryData) => {
        updateDataAfterCartItemDeletion(checkoutSummaryData);
      })
      .catch((error) => {
        console.log('error' + JSON.stringify(error));
      });
  }

  /* Checkout Validation */
  function validatePaymentMethod(dueToday?: string) {
    const autopayAgreementValid = hasAutopayPaymentOptionSelected
      ? hasAgreedToAutopay && !isSingleUseCard(selectedPaymentMethod)
      : true;
    const paymentMethodNotNeeded: boolean =
      !!dueToday &&
      parseFloat(dueToday) === 0 &&
      !hasAutopayPaymentOptionSelected;
    return (
      (!!selectedPaymentMethod && autopayAgreementValid) ||
      paymentMethodNotNeeded
    );
  }

  //TODO: This is a very strange function. What is happening here?
  function validatePaymentWaiver() {
    if (paymentWaiver) {
      updateHasAgreedToPaymentPolicy(hasAgreedToPaymentPolicy);
      return hasAgreedToPaymentPolicy;
    }

    return true;
  }

  function validateCheckout(dueToday?: string): {
    methodIsValid: boolean;
    waiverIsValid: boolean;
  } {
    const methodIsValid = validatePaymentMethod(dueToday);
    const waiverIsValid = validatePaymentWaiver();
    return { methodIsValid, waiverIsValid };
  }

  function updateErrorStates(
    displayMethodError: boolean,
    displayWaiverError: boolean
  ) {
    updatePaymentMethodErrors(displayMethodError);
    updatePaymentWaiverErrors(displayWaiverError);
  }
  /* */

  return {
    cartItems,
    cartUuid,
    checkoutErrorsAreVisible,
    checkoutFieldErrorStates,
    currency,
    discountCodeNames: discountCodeCodes,
    discountCodeErrors,
    discountCodes,
    dueToday,
    hasAgreedToAutopay,
    hasAutopayPaymentOptionSelected,
    hasAgreedToPaymentPolicy,
    isLoading,
    paymentWaiver,
    selectedPaymentMethod,
    submissionErrors,
    submissionErrorsAreVisible,
    subtotal,
    temporaryPaymentMethod,
    totalAmount,
    transactionFees,
    storedPaymentMethods,
    handleCartItemDeletion,
    handleDiscountCodeUpdateSubmission,
    handleCheckoutErrorVisibilityUpdate: updateCheckoutErrorsAreVisible,
    handleHasAgreedToAutopayUpdate: updateHasAgreedToAutopay,
    handleHasAgreedToPaymentPolicy: updateHasAgreedToPaymentPolicy,
    handlePayLaterSelection,
    handlePaymentMethodSelection,
    handlePaymentPlanSelection,
    handlePaymentWaiverUpdate: updatePaymentWaiver,
    handleRegistrationRemoval,
    handleTemporaryPaymentMethodSelection: updateTemporaryPaymentMethod,
    handleStoredPaymentMethodsUpdate: updateStoredPaymentMethods,
    handleSubmissionErrorsUpdate: updateSubmissionErrors,
    handleSubmissionErrorsVisibilityUpdate: updateSubmissionErrorsAreVisible,
    updateErrorStates,
    validateCheckout,
  };
};

/* Utility Functions */
export function updatePayLaterInCartItemPaymentPlans(
  cartItems: CombinedCartItemData[],
  ngPaymentPlanId: string,
  payLater: boolean
): CombinedCartItemData[] {
  // Create a deep copy of the cart items to avoid mutating the original
  const updatedCartItems = JSON.parse(
    JSON.stringify(cartItems)
  ) as CombinedCartItemData[];

  for (const cartItem of updatedCartItems) {
    // Update in paymentPlanSummaries
    if (cartItem.paymentPlanSummaries) {
      for (const planSummary of cartItem.paymentPlanSummaries) {
        if (planSummary.ngPaymentPlanId === ngPaymentPlanId) {
          planSummary.payLater = payLater;
        }
      }
    }
    // Update in selectedPaymentPlan if it matches
    if (cartItem.selectedPaymentPlan.ngPaymentPlanId === ngPaymentPlanId) {
      cartItem.selectedPaymentPlan.payLater = payLater;
    }
  }
  return updatedCartItems;
}

export function updateDiscountCodes(
  state: ConsolidatedCheckoutData,
  discountCode: DiscountCode,
  action: 'add' | 'remove'
): ConsolidatedCheckoutData {
  const { discountCodes = [] } = state;
  const newDiscountCodes =
    action === 'add'
      ? [...discountCodes, discountCode]
      : discountCodes.filter((code) => code.code !== discountCode.code);
  return { ...state, discountCodes: newDiscountCodes };
}

export function updatePaymentPlansInCartItems(
  cartItems: CombinedCartItemData[],
  selectedCartItemUuid: string,
  updatedPaymentPlanSelection: PaymentPlanSummary
) {
  const cartItemsToUpdate = [...cartItems];
  const cartItemToUpdate = cartItemsToUpdate.findIndex(
    (cartItem) => cartItem.cartItemUuid === selectedCartItemUuid
  );
  const updatedCartItem = {
    ...cartItemsToUpdate[cartItemToUpdate],
    paymentTerm: getPaymentTermFromPaymentPlan(updatedPaymentPlanSelection),
    selectedPaymentPlan: updatedPaymentPlanSelection,
  };
  cartItemsToUpdate.splice(cartItemToUpdate, 1, updatedCartItem);
  return cartItemsToUpdate;
}

export function getPaymentTermFromPaymentPlan(
  paymentPlan: PaymentPlanSummary
): PaymentTerm {
  return paymentPlan.installments && paymentPlan.installments.length > 0
    ? 'PAYMENT_PLAN'
    : 'FULL';
}

export function getDefaultPaymentMethod(
  storedPaymentMethods: PaymentMethodInfo
) {
  if (!storedPaymentMethods) {
    return null;
  }

  const { paymentOptionsCount, storedCreditCards, storedBankAccounts } =
    storedPaymentMethods;
  if (paymentOptionsCount === 0) {
    return null;
  } else if (
    storedCreditCards.some(
      (storedCreditCard) => storedCreditCard.isPrimaryPaymentOption
    )
  ) {
    return storedCreditCards.find(
      (storedCreditCard) => storedCreditCard.isPrimaryPaymentOption
    );
  } else if (
    storedBankAccounts.some(
      (storedBankAccount) => storedBankAccount.isPrimaryPaymentOption
    )
  ) {
    return storedBankAccounts.find(
      (storedBankAccount) => storedBankAccount.isPrimaryPaymentOption
    );
  }
  return null;
}

export function getUpdatedDiscountCodeCodes(
  discountCodes: DiscountCode[],
  discountCodeToUpdate: string,
  addOrRemove: string
) {
  if (addOrRemove === 'add') {
    return [
      ...discountCodes.map((discountCode) => discountCode.code),
      discountCodeToUpdate,
    ];
  } else {
    return discountCodes
      .filter((discountCode) => discountCode.code !== discountCodeToUpdate)
      .map((discountCode) => discountCode.code);
  }
}

export function getUniqueDiscountCodeCodes(discountCodes: DiscountCode[]) {
  const discountCodeNames: string[] = [];
  for (const discountCode of discountCodes) {
    if (!discountCodeNames.includes(discountCode.code)) {
      discountCodeNames.push(discountCode.code);
    }
  }
  return discountCodeNames;
}

export function cartItemsIncludeAutopayPaymentPlan(
  cartItems: CombinedCartItemData[]
) {
  const selectedPaymentPlans = cartItems.map(
    (cartItem) => cartItem.selectedPaymentPlan
  );
  return selectedPaymentPlans.some(
    (selectedPaymentPlan) => selectedPaymentPlan.autopayRequired
  );
}

export function extractFormattedMessageFromError(
  messages: { level: 'INFO' | 'WARN'; message: string }[]
) {
  const warningMessage = messages.find((message) => message.level === 'WARN');
  if (warningMessage?.message) {
    const discountCode = extractDiscountCode(warningMessage.message);
    return `Could not apply ${discountCode} because it is invalid or it has expired.`;
  }
  return 'This code is invalid or expired.';
}

/**
 * Extracts a discount code from an error message string
 * @param errorMessage - The error message containing the discount code
 * @returns The extracted discount code or null if not found
 */
function extractDiscountCode(errorMessage: string): string | null {
  // Using regex to match the pattern "discount code X because"
  const regex = /could not apply discount code\s+(\S+)\s+because/i;
  const match = regex.exec(errorMessage);

  // Return the captured group (the discount code) if found, otherwise null
  return match ? match[1] : null;
}

export {
  ConsolidatedCheckoutContext,
  ConsolidatedCheckoutProvider,
  useConsolidatedCheckout,
};

/**
 * Removes a specific registration from a subprogram within a cart item
 *
 * @param cartItems - The array of cart items
 * @param cartItemUuid - The UUID of the cart item containing the subprogram
 * @param subprogramId - The ID of the subprogram containing the registration
 * @param registrationId - The ID of the registration to remove
 * @returns A new array of cart items with the specified registration removed
 */
export function removeRegistrationFromCartItem(
  cartItems: CombinedCartItemData[],
  cartItemUuid: string,
  subprogramId: number,
  registrationId: string
): CombinedCartItemData[] {
  // Create a deep copy of the cart items to avoid mutating the original
  return cartItems.map((cartItem) => {
    // Skip if this is not the target cart item
    if (cartItem.cartItemUuid !== cartItemUuid) {
      return cartItem;
    }

    // If the cart item doesn't have subprograms, return it unchanged
    if (!cartItem.subprograms || cartItem.subprograms.length === 0) {
      return cartItem;
    }

    // Create a new cart item with updated subprograms
    return {
      ...cartItem,
      subprograms: cartItem.subprograms.map((subprogram) => {
        // Skip if this is not the target subprogram
        if (subprogram.subprogramId !== subprogramId) {
          return subprogram;
        }

        // Filter out the target registration
        return {
          ...subprogram,
          registrations: subprogram.registrations.filter(
            (registration) => registration.registrationId !== registrationId
          ),
        };
      }),
    };
  });
}
